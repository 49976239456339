import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import Image from "next/image"
import { useSettings } from "src/hooks/use-settings";

interface PoweredByMySherpasProps {
    open: boolean;
}

export const PoweredByMySherpas = (props: PoweredByMySherpasProps) => {
    const { version } = useSettings();

    return <Stack
        row
        data-ui={`${props.open ? 'active' : 'hidden'}`}
        className={`space-x-2 w-full data-hidden:pl-2 h-10 overflow-hidden data-hidden:space-x-0 transition-all will-change-auto`}>
        <div className='shrink-0'>
            <Image
                src='/static/android-icon-72x72.png'
                alt="mysherpas"
                className='rounded-full shrink-0'
                width='32'
                height='32'
                layout='fixed'
            />
        </div>
        <Stack space="sm" className='data-hidden:w-0 w-full overflow-hidden transition-all will-change-auto' data-ui={props.open ? "active" : 'hidden'}>
            <Text variant="inherit" size="xs" truncate weight="medium">
                Powered by mysherpas
            </Text>
            <Text variant="inherit" size="xs" weight="medium" truncate>
                {version}
            </Text>
        </Stack>
    </Stack>
};